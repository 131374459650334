import { ExternalLink, Heading } from "@ksoc-private/ui-core";
import type {
  ErrorBrowserLocationChangeRequired,
  LoginFlow,
  UpdateLoginFlowBody,
} from "@ory/client";
import type { MetaFunction } from "@remix-run/cloudflare";
import { redirect } from "@remix-run/cloudflare";
import { Link, useActionData, useLoaderData } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import {
  AuthenticationForm,
  AuthenticationFormWrapper,
} from "~/components/AuthenticationForm";
import { FlowError } from "~/components/kratos/FlowError";
import { FlowUi } from "~/components/kratos/FlowUi";
import { AuthUserError, createKratosClient } from "~/services/kratos.server";
import { getSession } from "~/services/session.server";
import { extractKratosResponseHeaders } from "~/util/convertHeaderTypes";
import { notEnterpriseSSOProvider } from "~/util/enterpriseSSO";
import { isAxiosError } from "~/util/isAxiosError";
import type { ActionArgs, LoaderArgs } from "~/util/types";

export const handle = { i18n: ["auth"] };

export const meta: MetaFunction = () => {
  return [{ title: "RAD Security - Sign in" }];
};

//  Annoyingly need this as we have to use the `Response.json` due to the `session.server` forcing use to use a `Response` type.
//  We should move this to support remix `data()` at some point
type LoaderData = LoginFlow & {
  error?: {
    id: string;
    message: string;
  };
};

export async function loader({ request, context }: LoaderArgs) {
  const kratos = createKratosClient(context);
  const session = await getSession(request, context.env.SESSION_SIGNING_KEY);

  try {
    const { data: flow, headers } = await kratos.createBrowserLoginFlow({
      refresh: true,
      cookie: request.headers.get("cookie")!,
    });
    session.set("flowId", flow.id);

    const url = new URL(request.url);
    if (url.searchParams.has("redirect")) {
      const { pathname, search } = new URL(
        url.searchParams.get("redirect")!,
        request.url,
      );
      session.set("redirectAfterLogin", pathname + search);
    }

    return session.commitWithResponse(
      Response.json(
        { ...flow },
        {
          headers: extractKratosResponseHeaders(headers),
          status: 200,
        },
      ),
    );
  } catch (error) {
    if (!isAxiosError<LoginFlow>(error)) throw error;
    const { data: errorData, headers } = error.response ?? {};
    if (typeof errorData === "string") throw new AuthUserError(error);
    if (error.code === "ERR_NETWORK") throw error;

    return session.commitWithResponse(
      Response.json(
        { ...errorData },
        {
          headers: extractKratosResponseHeaders(headers),
          status: error.response?.status ?? 400,
        },
      ),
    );
  }
}

export async function action({ request, context }: ActionArgs) {
  const kratos = createKratosClient(context);
  const session = await getSession(request, context.env.SESSION_SIGNING_KEY);
  const flowId = session.get("flowId") as string;

  if (!flowId) {
    //  They must have completed signing in, lets just refresh and handle it on the other side
    return redirect("/", { status: 302 });
  }

  const formData = await request.formData();

  try {
    const { headers } = await kratos.updateLoginFlow({
      flow: flowId,
      updateLoginFlowBody: Object.fromEntries(
        formData,
      ) as unknown as UpdateLoginFlowBody,
      cookie: request.headers.get("cookie")!,
    });
    session.unset("flowId");

    let redirectUrl = "/";
    if (session.has("redirectAfterLogin")) {
      const possibleRedirectUrl = session.get("redirectAfterLogin")!;
      if (possibleRedirectUrl && possibleRedirectUrl.startsWith("/sign-out")) {
        session.unset("redirectAfterLogin");
      } else {
        redirectUrl = possibleRedirectUrl;
      }
    }

    return session.commitWithResponse(
      redirect(redirectUrl, {
        headers: extractKratosResponseHeaders(headers),
      }),
    );
  } catch (error) {
    if (!isAxiosError<LoginFlow | ErrorBrowserLocationChangeRequired>(error)) {
      throw error;
    }
    const { data: errorData, headers } = error.response ?? {};
    if (errorData)
      if (typeof errorData === "string") throw new AuthUserError(error);
    if (
      errorData &&
      "redirect_browser_to" in errorData &&
      errorData.redirect_browser_to
    ) {
      const oidcUrl = new URL(errorData.redirect_browser_to);
      return session.commitWithResponse(
        redirect(oidcUrl.toString(), {
          headers: extractKratosResponseHeaders(headers),
        }),
      );
    }

    return session.commitWithResponse(
      Response.json(errorData, {
        headers: extractKratosResponseHeaders(headers),
        status: error.response?.status ?? 400,
      }),
    );
  }
}

export default function SignIn() {
  const { t } = useTranslation("auth");
  const loaderData = useLoaderData<typeof loader>() as LoaderData;
  const actionData = useActionData<typeof action>() as LoaderData;
  const flow =
    "error" in loaderData
      ? { ...actionData, ...loaderData }
      : (actionData ?? loaderData);

  return (
    <AuthenticationFormWrapper>
      <AuthenticationForm data-test-id="sign-in-form">
        <Heading as="h2" size="xl" className="tw-text-center">
          {t("signIn.title")}
        </Heading>
        {"error" in flow && <FlowError error={flow.error!} />}
        {"ui" in flow && (
          <FlowUi
            groupOrder={["default", "password", "oidc"]}
            filterNodes={notEnterpriseSSOProvider}
            shouldHideNodeFromDefaultGroup={(nodeGroup, node) =>
              nodeGroup === "oidc" &&
              "name" in node.attributes &&
              node.attributes.name === "identifier"
            }
            {...(flow as LoginFlow)}
          />
        )}
        <p className="tw-mt-4 tw-text-sm tw-text-center tw-mb-0">
          <Link
            to="/recovery"
            className="tw-text-cyan-600 hover:tw-text-cyan-500"
          >
            {t("signIn.forgotPasswordLink")}
          </Link>
        </p>
        <p className="tw-mt-4 tw-text-sm tw-text-center tw-mb-0">
          <Link
            to="/enterprise-sso"
            className="tw-text-cyan-600 hover:tw-text-cyan-500"
          >
            {t("signIn.enterpriseSSOLink")}
          </Link>
        </p>
      </AuthenticationForm>
      <MSAAgreement />
    </AuthenticationFormWrapper>
  );
}

export function MSAAgreement() {
  return (
    <p className="tw-text-rad-blue-100 tw-mx-auto tw-items-center tw-text-xs tw-text-center tw-mt-4 tw-max-w-sm tw-text-pretty">
      By signing in you agree that usage of the RAD Security platform is
      governed by the terms and conditions of the Master Services Agreement
      executed between RAD Security and Customer (The "Agreement") located at{" "}
      <ExternalLink href="https://rad.security/msa">
        https://rad.security/msa
      </ExternalLink>{" "}
      or in the mutually negotiated MSA contemporaneously signed by both parties
    </p>
  );
}
